import { useChargeQtyAdjMaintenanceTracked } from "presentation/store/ChargeQtyAdj/ChargeQtyAdjMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import ChargeQtyAdjMaintenance from "./ChargeQtyAdjMaintenance";
import { ChargeQtyAdjMaintenanceFormPanel } from "./ChargeQtyAdjMaintenanceFormPanel";
export const ChargeQtyAdjMasterView: React.FC = () => {
    const [chargeQtyAdjState] = useChargeQtyAdjMaintenanceTracked();
    const { isAdd, isEditable, isRead } = chargeQtyAdjState.masterState;
    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            <ChargeQtyAdjMaintenance />
            {
                ((isAdd || isEditable || isRead) &&
                    <SliderPanel
                        isOpen={false}
                        leftSectionWidth={"100%"}
                        rightSectionWidth={"0%"}
                        leftChildren={<ChargeQtyAdjMaintenanceFormPanel />}
                    />
                )
            }
        </div>
    </>
} 
